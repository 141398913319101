import React from "react";

const Topbar = () => {

    return (
        <>
            <div className="container-fluid bg-light ps-5 pe-0 d-none d-lg-block">
                <div className="row gx-0">
                    <div className="col-md-6 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center">
                            <small className="py-2"><i className="far fa-clock text-success me-2"></i>Opening Hours: Mon - Sat : 10.00 am - 07.00 pm, Sunday Closed </small>
                        </div>
                    </div>
                    <div className="col-md-6 text-center text-lg-end">
                        <div className="position-relative d-inline-flex align-items-center bg-success text-white top-shape px-5">
                            <div className="me-3 pe-3 border-end py-2">
                                <p className="m-0"> <a href="mailto:info@elitelisten.com" className="text-light"> <i className="fa fa-envelope-open me-2"></i>info@elitelisten.com</a></p>
                            </div>
                            <div className="py-2">
                                <p className="m-0"><a href="tel:+91-9911917723" className="text-light"><i className="fa fa-phone-alt me-2"></i>+91-9911917723</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Topbar;